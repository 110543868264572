.darkButton {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 43px;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  height: 43px;
  justify-content: center;
  margin-right: 3vw;
  /* the ripple shouldn't exceed past the button */
  overflow: hidden;
  padding: 0;
  /* position the ripple absolutely inside the button */
  position: relative;
  width: 43px;
}

.darkButton:hover {
  background-color: #282828;
  cursor: pointer;
}

.light .darkButton {
  color: #313131;
}

.light .darkButton:hover {
  background-color: #F0F0F0;
}

@media (max-width: 372px) {
  .darkButton {
    margin-right: 10px;
  }
}

@media (max-width: 300px) {
  .darkButton {
    height: 37.5px;
    width: 37.5px;
  }
}