.bottomButton {
  border-radius: 15px;
  border: none;
  cursor: pointer;
  /* needed due to user agent stylesheet override */
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  height: 50px;
  /* so the ripple doesn't show outside the borders */
  overflow: hidden;
  padding: 0 12px;
  /* for the ripple positioning */
  position: relative;
}

.buttonGrid .movesetButton:nth-child(13) {
  border-bottom-left-radius: 10px;
}

.buttonGrid .movesetButton:nth-child(18) {
  border-bottom-right-radius: 10px;
}

.cancelButton {
  margin-top: 5px;
}

.isToggled {
  transition: 0.2s;
}

.movesetButton {
  border-radius: 0;
  border: none;
  color: #757575;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 14.66px;
  font-weight: 500;
  height: 34px;
  /* take 1/6th of the button grid which is a blocking div inside the queryFormContainer */
  width: calc(100% / 6);
}

.queryFormBorder {
  border-radius: 10px;
  margin-bottom: 20px;
}

.queryFormContainer {
  /* make the width capped at true 650px including padding */
  box-sizing: border-box;
  /* take up half of the width (but get capped via max width), or half of height (but get capped via .topHalf max height) */
  flex: 1;
  max-width: 650px;
  padding: 50px 0 0 5%;
  /* large screen positionings */
}

/* turn off default button outline on android */
.queryFormContainer button:active,
button:focus {
  outline: none;
}

.queryFormContainer input {
  border: none;
  /* make it true 100% width */
  box-sizing: border-box;
  /* needed due to user agent stylesheet override */
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  outline: none;
  padding: 0 35px;
  /* expand to fill full queryFormContainer */
  width: 100%;
}

.queryFormContainer input:focus::placeholder {
  /* make the placeholder disappear when clicked in */
  color: transparent;
}

/* remove rounded corners on inputs for ios */
.queryFormContainer input:not([type="radio"]):not([type="checkbox"]) {
  -webkit-appearance: none;
  border-radius: 0;
}

.queryFormContainer label {
  /* this also makes the width of the label 100% since labels are not by default */
  display: flex;
  /* needed due to user agent stylesheet override */
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  padding: 10px 0 10px 20px;
  text-transform: uppercase;
}

.randomExampleButton {
  margin-right: 12px;
  margin-top: 5px;
}

.scrambleLabel {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.submitAndCancelAndRandom {
  display: flex;
}

/* override user agent stylesheet */
.submitAndCancelAndRandom button {
  font-size: 13.33px;
}

.submitButton {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 5px 10px 0 0;
  min-width: 90px;
}

.submitButton p {
  line-height: 1.4;
}

@media (hover: hover) {
  .cancelButton:hover {
    background-color: #7d3b41;
    transition: 0.2s;
  }

  .submitButton:hover,
  .randomExampleButton:hover {
    background-color: #3e5d87;
    transition: 0.2s;
  }

  /* get rid of sticky hover when clicking in a field on mobile */
  .queryFormContainer input:hover:not(:focus) {
    background-color: #4c4c4c;
  }

  /* get rid of sticky hover when clicking in a field on mobile */
  .queryFormContainer .movesetButton:hover:not(.isToggled) {
    background-color: #4c4c4c;
    transition: 0.2s;
  }
}

/** light mode */
.light .isToggled {
  color: #f3f1fe;
}

.light .spinner {
  color: #313131;
}

@media (hover: hover) {
  .light .cancelButton:hover {
    background-color: #782c2b;
    transition: 0.2s;
  }

  /* get rid of sticky hover when clicking in a field on mobile */
  .light .queryFormContainer .movesetButton:hover:not(.isToggled) {
    background-color: #d6d3d3;
  }

  /* get rid of sticky hover when clicking in a field on mobile */
  .light .queryFormContainer input:hover:not(:focus) {
    background-color: #d6d3d3;
  }

  .light .submitButton:hover,
  .light .randomExampleButton:hover {
    background-color: #303F9F;
    transition: 0.2s;
  }
}

/** media queries */

@media (max-width: 767px) {
  .movesetButton {
    height: 40px;
  }

  .queryFormContainer {
    /* changing the sizing style since the padding is a more significant part of the screen width, this is easier to work with */
    box-sizing: border-box;
    max-width: none;
    /* move query form up to use extra space */
    padding: 30px 10px 10px 10px;
    /* once we flex vertically, fill the whole screen */
    width: 100%;
  }
}

@media (max-width: 500px) {
  .randomExampleButton {
    line-height: 1.5;
    max-width: 120px;
  }
}

@media (max-width: 300px) {
  .bottomButton {
    padding: 0 6px;
  }
}