.animateButton {
  /* push the animate and copy buttons to the right */
  margin-left: auto;
}

.animateButton,
.copyButton {
  background-color: #555;
  border: none;
  border-radius: 3px;
  color: #e4e4e4;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
}

@media (hover: hover) {
  .animateButton:hover,
  .copyButton:hover {
    background-color: #3c3c3c;
    transition: 0.2s;
  }
}


.buttonContainer {
  /* this allows the buttons to have their correct heights */
  align-items: center;
  display: flex;
}

.scrollableSolutions {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color-scheme: dark;
  flex: 1;
  overflow-y: scroll;
}

.solution {
  margin-right: 8px;
}

.solutionLi {
  line-height: 2;
  border-bottom: 1px solid #121212;
  padding-bottom: 5px;
  padding-top: 5px;
}

.solutionsDisplay {
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  /* take up 95% of the container */
  max-height: 95%;
  width: 100%;
}

.solutionsDisplayContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  /* ? can use a non-flexbox */
  /* the solutionsDisplayContainer will flex 1/2 of the solvePageMinusNav height */
  flex: 1;
  justify-content: center;
  /* this allows the container to shrink somewhat as height is not set otherwise, but it caps out at a certain point, so the scrollable solutions is visible */
  min-height: 300px;
  padding: 0;
  padding: 25px 5%;
  /* allows for positioning of the CopyPopup */
  position: relative;
}

.solutionsHeader {
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  padding: 12px 5px 12px 15px;
}

.solutionsHeaderSpacer {
  /* horizontal spacer in the header to push sort buttons to the right */
  flex: 1;
}

.solutionsIcon {
  margin-left: 4px;
  margin-right: 10px;
}

.solutionsUl {
  list-style-type: none;
  margin-top: 5px;
  padding: 0 12px 0 12px;
}

.sortButton {
  border: none;
  border-radius: 10px;
  cursor: pointer;
  /* needed due to user agent stylesheet override */
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  margin-right: 8px;
  /* for the ripples */
  overflow: hidden;
  padding: 5px 10px;
  /* for the ripples */
  position: relative;
}

@media (hover: hover) {
  .sortButton:hover {
    background-color: #3e5d87;
    transition: 0.2s;
  }
}

.solutionLi .copyButton {
  /* add some spacing between the solution and number */
  margin-left: 10px;
  /* when solutions go on two lines this keeps it lined up */
  text-align: left;
}

/** light mode */
.light .animateButton,
.light .copyButton {
  background-color: #f7f7f7;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  color: #313131;
}

.light .solutionLi {
  border-bottom-color: #d9d7d7;
}

 /* get rid of sticky hover when clicking in a field on mobile */
@media (hover: hover) {
  .light .animateButton:hover,
  .light .copyButton:hover {
    background-color: #ECECEC;
    transition: 0.2s;
  }
  .light .scrollableSolutions {
    color-scheme: normal;
  }

  .light .sortButton:hover {
    background-color: #303F9F;
    transition: 0.2s;
  }

}


/** media queries */
@media (max-width: 767px) {
  .solutionsDisplayContainer {
    /* changing the sizing style since the padding is a more significant part of the screen width, this is easier to work with */
    box-sizing: border-box;
    /* takes up 1/3rd of the solvePageMinusNav, allowing the cube visual to have enough room */
    flex: 0.5;
    padding: 5px 10px;
    width: 100%;
  }
}
