body {
  /* react boilerplate */
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* my styling */
  /* remove blue flash on some responsive sites */
  -webkit-tap-highlight-color: transparent;
  background-color: #121212;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-weight: 500;
}

button {
  /* override user style sheet */
  letter-spacing: 0.01rem;
}

/* react boilerplate */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.accentColor {
  background-color: #4A6FA5;
}

.accentColorText {
  color: #0f0f0f;
}

.errorColor {
  background-color: #9B4D54;
  color: #0f0f0f;
}

.mainColor {
  background-color: #1e1e1e;
}

.mainText {
  color: #e4e4e4;
}

.secondaryColor {
  background-color: #222;
}

.shadow {
  box-shadow:
    /* offset-x | offset-y | blur-radius | spread-radius | color */
    0px 8px 10px 1px hsla(0, 0%, 0%, 0.14),
    0px 3px 14px 2px hsla(0, 0%, 0%, 0.12),
    0px 5px 5px -3px hsla(0, 0%, 0%, 0.2);
}

.topHalf {
  /* ? top half can be removed and just the items are used */
  display: flex;
  /* the top half will occupy 1/2 of the solvePageMinusNav height */
  flex: 1;
}

#root {
  display: flex;
  flex-direction: column;
  /* root should always take full VH unless the content overflows it such as small screens for the solve page, or the documentation page, which will cause scrollbars */
  height: 100vh;
}

/** light mode */

body.light {
  background-color: #FAFAF9;
}

.light .accentColor {
  background-color: #4051B5;
}

.light .accentColorText {
  color: #f3f1fe;
}

.light .errorColor {
  background-color: #8C3432;
  color: #f3f1fe;
}

.light .mainColor {
  background-color: #e7e7e7;
}

.light .mainText {
  color: #313131;
}

.light .secondaryColor {
  background-color: #f2f2f2;
}

.light .shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

/** media queries */

@media (max-width: 767px) {
  .topHalf {
    /* top half should be center on a mobile view */
    align-items: center;
    /* flex the queryFormContainer and cubePanel vertically */
    flex-direction: column;
    /* top half takes up more space compared to solutions display when in vertical flex mode */
    flex: 1;
    /* in the stacked few, prevent the cube from becoming too big */
    max-height: 700px;
    /* top half should now fill the width of the wholePageMinusNavbar div */
    width: 100%;
  }
}