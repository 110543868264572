twisty-player {
  height: 100%;
  width: 100%;
}

.cubePanel {
  box-sizing: border-box;
  /* fill out the width since cubePanel will get capped, in height mode keep expanding until .topHalf caps the height */
  flex: 1;
  /* normally the cube expands or shrinks to fill the remainder of the page, for small phones we don't want the cube to keep shrinking, we would rather scroll. By adding a minimum height, the sum of heights of our content will overflow the 100vh height allowing for the scroll effect. */
  min-height: 100px;
  max-height: 450px;
  padding: 15px 0 35px 0;
  /* to position drag cube to view icon and text */
  position: relative;
}

.dragIconAndText {
  bottom: 8%;
  pointer-events: none;
  position: absolute;
  right: 15%;
}

.dragText {
  cursor: default;
  display: none;
}


/** moving around cube, drag icon, and text */

@media(min-width: 768px) {
  .dragTextLarge {
    display: inline-block;
  }
}

@media (max-width: 1690px) {
  .dragIconAndText {
    right: 10%;
  }
}

@media (max-width: 1600px) {
  .cubePanel {
    padding: 25px 0 45px 0;
  }
}

@media (max-width: 1560px) {
  .cubePanel {
    padding: 40px 0 60px 0;
  }

  .dragIconAndText {
    bottom: 12%;
  }
}

@media (max-width: 1430px) {
  .cubePanel {
    padding: 60px 0 80px 0;
  }

  .dragIconAndText {
    bottom: 14%;
    right: 10%;
  }
}

@media (max-width: 1340px) {
  .dragIconAndText {
    bottom: 3%;
    right: 37%;
  }
}

@media (max-width: 1280px) {
  .dragIconAndText {
    right: 36%;
  }
}

@media (max-width: 1230px) {
  .dragIconAndText {
    right: 35%;
  }
}

@media (max-width: 1100px) {
  .cubePanel {
    padding: 80px 0px 100px 0px;
  }

  .dragIconAndText {
    bottom: 6%;
    right: 34%;
  }
}

@media (max-width: 1030px) {
  .dragIconAndText {
    right: 33%;
  }
}

@media (max-width: 940px) {
  .cubePanel {
    padding: 100px 20px 120px 20px;
  }

  .dragIconAndText {
    bottom: 9%;
    right: 32%;
  }
}

@media (max-width: 870px) {
  .dragIconAndText {
    right: 31%;
  }
}

@media (max-width: 820px) {
  .dragIconAndText {
    right: 30%;
  }
}

@media (max-width: 767px) {
  .cubePanel {
    /* once the components are stacked vertically, allow the cube to shrink to less than 50% of the height. This is needed as the queryForm is fixed height and the cube will otherwise be hardcoded at 50% */
    flex-shrink: 1;
    /* make room for cube on smaller screen */
    padding: 0;
     /* width was not defined in laptop view */
    width: 100%;
  }

  .dragIconAndText {
    bottom: 0;
    right: 10%;
  }
}

@media (min-width: 361px) and (max-width: 767px) {
  .dragTextMedium {
    display: inline-block;
  }
}

@media (max-width: 481px) {
  .dragIconAndText {
    bottom: -3%;
    position: absolute;
    right: 6%;
  }
}

@media(max-width: 360px) {
  .dragTextSmall {
    display: inline-block;
  }
}