.noSolutionsModal {
  border-radius: 5px;
  left: 50%;
  padding: 10px;
  position: fixed;
  top: 75%;
  transform: translate(-50%, -50%);
  width: max-content;
  z-index: 1;
}

.noSolutionsModal p {
  color: white;
  margin: 0;
}