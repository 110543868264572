.navbarDiv {
  /* center the toggle */
  align-items: center;
  /* set color so when scrolling the navbar is not see through */
  background-color: #121212;
  border-bottom: 2px solid #1e1e1e;
  display: flex;
  /* navbar contains the UL and the toggle so this spaces them out */
  justify-content: space-between;
  /* make navbar stay on the top, particularly for documentation page */
  position: fixed;
  /* expand to width of the page */
  width: 100%;
  z-index: 1;
}

.navbarDiv li a {
  /* for unactive tabs */
  color: white;
  display: block;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
}

/* don't need to handle sticky hover for mobile, as when we click on a page in mobile it will always change to be active */
.navbarDiv li a:hover:not(.accentColorText) {
  background-color: #282828;
}

.navbarUl {
  display: flex;
  list-style-type: none;
  padding-left: 3vw;
}


/** light mode */
.light li a {
  color: #313131;
}

/* don't need to handle sticky hover for mobile, as when we click on a page in mobile it will always change to be active */
.light li a:hover:not(.accentColorText) {
  background-color: #F0F0F0;
}

.light .active {
  color: #f3f1fe;
}

.light .navbarDiv {
  background-color: #FAFAF9;
  border-bottom-color: #e7e7e7;
}


/** media queries */

@media (max-width: 372px) {

  /* on very thin screens make the nav elements closer together */
  .navbarUl {
    padding-left: 10px;
  }
}

@media (max-width: 300px) {

  /* the navbar text is too big */
  .navbarDiv {
    font-size: 12px;
  }
}