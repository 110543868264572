.iconAndTooltip {
  position: relative;
}

.tooltip {
  border-radius: 5px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  /* by default do not show or take up space */
  display: none;
  font-size: 14px;
  left: 30px;
  line-height: 1.5;
  padding: 5px 10px;
  /* positioned relative to the .iconAndTooltip */
  position: absolute;
  /* override all caps that came from the labels */
  text-transform: none;
  /* appear above other info tags in certain viewports */
  z-index: 1;
}

/* needed to handle the scramble as we don't want that to split into two lines */
.scrambleTooltip {
  width: max-content;
}

.scrambleTooltip,
.depthTooltip,
.movesetTooltip {
  /* override label font-weight */
  font-weight: 500;
}

/* make all tooltips visible on hover, display type does not really matter on mobile, these function as buttons due to sticky hover */
.depthIcon:hover+.depthTooltip {
  display: inline;
}

.movesetIcon:hover+.movesetTooltip {
  display: inline;
}

.scrambleIcon:hover+.scrambleTooltip {
  display: inline;
}

.solutionsIcon:hover+.solutionsTooltip {
  display: inline;
}

/* base widths for the other tooltip, the tooltip will not tightly wrap the p element as you need JS for that */
.movesetTooltip p {
  width: 250px;
}

.depthTooltip p {
  width: 250px;
}

.solutionsTooltip p {
  width: 290px;
}

/* add some margins between p tags for spacing */
.tooltip p {
  margin-bottom: 3px;
  margin-top: 3px;
}

.queryFormContainer .icon {
  margin-left: 4px;
}

/** media queries */

/* start to move over tooltips when width shrinnks */
@media (max-width: 560px) {

  .scrambleTooltip,
  .depthTooltip,
  .movesetTooltip,
  .solutionsTooltip {
    left: -50px;
    top: 30px;
  }
}

@media (max-width: 480px) {

  .scrambleTooltip,
  .depthTooltip,
  .movesetTooltip,
  .solutionsTooltip {
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
  }


  .depthTooltip p,
  .movesetTooltip p,
  .solutionsTooltip p {
    width: 80vw;
  }
}

@media (max-width: 350px) {
  .scrambleTooltip p {
    width: 80vw;
  }
}