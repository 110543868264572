.cubeAndCaption {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 300px;
}

.cubePicture {
  max-width: 70%;
}

/* width is forced to max since the document flexes vertically */
.cubeRow {
  display: flex;
  justify-content: center;
}

.documentationDiv {
  display: flex;
  flex-direction: column;
  /* since the navbar is taken out of flow with position: fixed; this pushes the elements down */
  margin-top: 70px;
  padding: 0px 4% 40px 4%;
}

.docuemntationDiv h2 {
  margin: 30px 0 0 0;
}

.cubeAndCaption p {
  /* p may be aligned within cubeAndCaption, but the text inside isn't, this is needed for when the caption is too long as per tony's explanation */
  text-align: center;
}

.documentationDiv a {
  color: #bb86fcc2;
  /* the links inside the table of contents did not have a cursor, likely due to how the default agent style sheet was set */
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}

.documentationDiv h2,
.documentationDiv h3,
.documentationDiv p,
.documentationDiv li {
  color: white;
}

.documentationDiv hr {
  margin: 30px 0;
}

.madeThisContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.madeThisContainerText {
  flex: 1;
}

.tableOfContents {
  border-radius: 15px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
  /* 17.55 is the h3 margin to keep the sizing consistent */
  padding: 0 20px 17.55px 20px;
}

.tableOfContentsUl {
  margin: 0;
  padding-left: 20px;
}

.technicalTable,
.generalTable {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 45%;
}

.youtubeVideo {
  min-width: 420px;
}

/** media queries */
@media (max-width: 1050px) {
  .tableOfContents {
    align-items: center;
    flex-direction: column;
    margin: auto;
    width: 90%;
    /* padding-left: 10%;
    padding-right: 10%; */
  }

  .technicalTable,
  .generalTable {
    max-width: none;
    width: 70%;
  }
}

/** light mode */
.light .documentationDiv a {
  color: #6200ee;
}

.light .documentationDiv h2,
.light .documentationDiv h3,
.light .documentationDiv p,
.light .documentationDiv li {
  color: #313131;
}