.alignCheckBox {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

/* specificity overrides .landingModal h2 */
#canSolveCube {
  margin-bottom: 24px;
}

/* override the react-floater property, this didn't seem accessible from the react-floater API, as removing shadow did not remove all of it */
.__floater {
  filter: none !important;
}

.landingModal {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.landingModal h2 {
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 10px 0;
  text-align: center;
}

.yesNoButton {
  border: none;
  border-radius: 10px;
  color: #f3f1fe;
  cursor: pointer;
  padding: 0 15px;
  /* needed due to user agent stylesheet override */
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
}

.yesNoButton p {
  margin: 0;
}